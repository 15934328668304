var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("ASuccessFour", { attrs: { api: _vm.api } }),
      _c(
        "div",
        { staticClass: "d-flex justify-start" },
        [
          _c(
            "v-btn",
            {
              staticClass: "mb-3",
              attrs: { color: "primary" },
              on: { click: _vm.redirectBack },
            },
            [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", [
            _c(
              "div",
              [
                _vm.api.isLoading
                  ? _c("v-skeleton-loader", {
                      ref: "skeleton",
                      attrs: { type: "table" },
                    })
                  : _vm._e(),
                this.companies != null && !_vm.api.isLoading
                  ? _c("v-data-table", {
                      staticClass: "elevation-1",
                      attrs: {
                        "show-select": "false",
                        "item-key": "company_id",
                        search: _vm.companiesSearch,
                        items: _vm.companies,
                        headers: this.companiesHeader,
                        dense: "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "top",
                            fn: function () {
                              return [
                                _c(
                                  "v-toolbar",
                                  { attrs: { flat: "", height: "auto" } },
                                  [
                                    _c(
                                      "v-row",
                                      { attrs: { "no-gutters": "" } },
                                      [
                                        _c(
                                          "v-col",
                                          [
                                            _c(
                                              "v-row",
                                              { staticClass: "ma-3" },
                                              [
                                                _c(
                                                  "v-toolbar-title",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold text-h4",
                                                  },
                                                  [_vm._v(" Companies ")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              { staticClass: "mt-5" },
                                              [
                                                _c("v-text-field", {
                                                  staticClass: "mx-4",
                                                  attrs: {
                                                    dense: "",
                                                    outlined: "",
                                                    label: "Search",
                                                  },
                                                  model: {
                                                    value: _vm.companiesSearch,
                                                    callback: function ($$v) {
                                                      _vm.companiesSearch = $$v
                                                    },
                                                    expression:
                                                      "companiesSearch",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              { staticClass: "mx-4 my-4" },
                                              [
                                                _vm.totalCompanies != null
                                                  ? _c("div", [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-h7 grey--text",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Total Companies Found : " +
                                                              _vm._s(
                                                                _vm.totalCompanies
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ])
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "item.index",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.companies.indexOf(item) + 1) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1717370319
                      ),
                      model: {
                        value: _vm.selectedCompanies,
                        callback: function ($$v) {
                          _vm.selectedCompanies = $$v
                        },
                        expression: "selectedCompanies",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("v-col", [
            _c("div", { staticClass: "px-3" }, [
              this.selectedCompanies != null &&
              Object.keys(this.selectedCompanies).length != 0
                ? _c(
                    "div",
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-toolbar",
                            {
                              attrs: { color: "secondary white--text text-h6" },
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "ma-3",
                                  attrs: { "no-gutters": "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c("v-toolbar-title", [
                                            _vm._v(" Selected companies "),
                                          ]),
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { color: "primary" },
                                              on: { click: _vm.submit },
                                            },
                                            [_vm._v(" Confirm ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "pt-3" },
                            _vm._l(
                              this.selectedCompanies,
                              function (company, index) {
                                return _c(
                                  "div",
                                  { key: index },
                                  [
                                    _c(
                                      "v-list-item",
                                      {
                                        attrs: {
                                          dense: "",
                                          outlined: "",
                                          "elevation-1": "",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(index + 1 + " . ") +
                                                  " " +
                                                  _vm._s(company.company_name) +
                                                  " "
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "mx-2" },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-toolbar",
                            {
                              attrs: { color: "secondary white--text text-h6" },
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "ma-3",
                                  attrs: { "no-gutters": "" },
                                },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c("v-toolbar-title", [
                                            _vm._v(" Selected companies "),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "pt-3" }, [
                            _c(
                              "span",
                              { staticClass: "text-h7 ma-3 grey--text" },
                              [_vm._v(" No Company Selected ")]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }